<app-navbar-style-two-2></app-navbar-style-two-2>

<section class="blog-details-area pb-100"
    style="
background-image: url('assets/img/banner-image/body2.png');  
background-size: 100% ;  

background-repeat: repeat-y;
padding-top: 20px;
font-family: 'Congenial';">
    <div class="container"
        style="background: transparent;">

        <div class="row justify-content-center">

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="flaticon-phone-call"></i>
                    </div>
                    <h3>Address</h3>
                    <p><a href="https://goo.gl/maps/CB4wtddZghdz7z2SA"
                            target="_blank">Sholashahar, Post: Chandgaon-4212, Panchlaish, Chattagram, Bangladesh</a>
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="flaticon-email"></i>
                    </div>
                    <h3>Email</h3>
                    <p><a href="mailto:jaskmctg@gmail.com">jaskmctg@gmail.com</a></p>
                    <p style="visibility: hidden;"><a href="mailto:jaskmctg@gmail.com">jaskmctg@gmail.com</a></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="flaticon-marker"></i>
                    </div>
                    <h3>Contact</h3>
                    <p><a href="tel:01309104702">01309-104702</a></p>
                    <p style="visibility: hidden;"><a href="tel:01309-104702">01309-104702</a></p>

                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc"
                    style="background-color: #FFFFFF; padding: 20px; border: 1px solid #eeeeee; margin-top: 30px;">

                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d118072.52689964585!2d91.86072460864256!3d22.362445181437074!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30ad27816309aeb7%3A0xcad5f4f134653769!2sJamea%20Ahmadia%20Sunnia%20Kamil%20Madrasah!5e0!3m2!1sen!2sbd!4v1684142808136!5m2!1sen!2sbd"
                        width="100%"
                        height="450"
                        style="border:0;"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    </div>
</section>

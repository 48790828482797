import { Component, OnInit } from '@angular/core';
import { HomeTranslationLoaderService } from 'src/services/home-translation-loader.service';
import { arabic, bangla, english } from 'src/services/locale';

@Component({
  selector: 'news-and-events',
  templateUrl: './news-and-events.component.html',
  styleUrls: ['./news-and-events.component.scss']
})
export class NewsAndEventsComponent implements OnInit
{
  dummyItems = [{
    "id": 1,
    "title": "Champion in Inter School Football Competition-2001",
    "url": '/',
    "date": 'Sep 13, 2022',
    "image": "assets/img/blog-image/blog2.jpg"

  }, {
    "id": 2,
    "title": "Champion in Inter School Football Competition-2002",
    "url": '/',
    "date": 'Sep 13, 2022',
    "image": "assets/img/blog-image/blog2.jpg"

  }, {
    "id": 3,
    "title": "Champion in Inter School Football Competition-2003",
    "url": '/',
    "date": 'Sep 13, 2022',
    "image": "assets/img/blog-image/blog2.jpg"

  }, {
    "id": 4,
    "title": "Champion in Inter School Football Competition-2004",
    "url": '/',
    "date": 'Sep 13, 2022',
    "image": "assets/img/blog-image/blog2.jpg"

  }, {
    "id": 5,
    "title": "Champion in Inter School Football Competition-2005",
    "url": '/',
    "date": 'Sep 13, 2022',
    "image": "assets/img/blog-image/blog2.jpg"

  }, {
    "id": 6,
    "title": "Champion in Inter School Football Competition-2006",
    "url": '/',
    "date": 'Sep 13, 2022',
    "image": "assets/img/blog-image/blog2.jpg"

  }, {
    "id": 7,
    "title": "Champion in Inter School Football Competition-2007",
    "url": '/',
    "date": 'Sep 13, 2022',
    "image": "assets/img/blog-image/blog2.jpg"

  }, {
    "id": 8,
    "title": "Champion in Inter School Football Competition-2008",
    "url": '/',
    "date": 'Sep 13, 2022',
    "image": "assets/img/blog-image/blog2.jpg"

  }, {
    "id": 9,
    "title": "Champion in Inter School Football Competition-2009",
    "url": '/',
    "date": 'Sep 13, 2022',
    "image": "assets/img/blog-image/blog2.jpg"

  }, {
    "id": 10,
    "title": "Champion in Inter School Football Competition-2010",
    "url": '/',
    "date": 'Sep 13, 2022',
    "image": "assets/img/blog-image/blog2.jpg"

  }]


  selectedLanguage = '';

  font = '';

  constructor(
    private _homeTranslationLoaderService: HomeTranslationLoaderService,

  )
  {
    this._homeTranslationLoaderService.loadTranslations(english, bangla, arabic);
  }

  ngOnInit(): void
  {
    this.selectedLanguage = localStorage.getItem('selectedLanguage');
  }

  GetFontName()
  {
    switch (this.selectedLanguage)
    {
      case 'en':
        return 'Congenial';
      case 'bn':
        return 'Kalpurush';
      case 'ar':
        return 'Jameel Noori Nastaleeq';
    }
  }

}

<app-navbar-style-two-2></app-navbar-style-two-2>

<section class="blog-details-area pb-100"
    style="
background-image: url('assets/img/banner-image/body2.png');  
background-size: 100% ;  

background-repeat: repeat-y;
padding-top: 20px;
font-family: 'Congenial';">
    <div class="container"
        style="background: transparent;">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div>
                        <a routerLink="/video-page"><img src="assets/img/blog-image/blog1.jpg"
                                alt="image"></a>
                    </div>
                    <div style="padding: 25px;">
                        <div>
                            <ul style="padding-left: 0;
                            margin-bottom: 0;
                            list-style-type: none;">
                                <li style="display: inline-block;
                                margin-right: 15px;
                                font-size: 15px;">June 24, 2022</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/video-page">Video 1</a></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div>
                        <a routerLink="/video-page"><img src="assets/img/blog-image/blog1.jpg"
                                alt="image"></a>
                    </div>
                    <div style="padding: 25px;">
                        <div>
                            <ul style="padding-left: 0;
                            margin-bottom: 0;
                            list-style-type: none;">
                                <li style="display: inline-block;
                                margin-right: 15px;
                                font-size: 15px;">June 22, 2022</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/video-page">Video 2</a></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div>
                        <a routerLink="/video-page"><img src="assets/img/blog-image/blog1.jpg"
                                alt="image"></a>
                    </div>
                    <div style="padding: 25px;">
                        <div>
                            <ul style="padding-left: 0;
                            margin-bottom: 0;
                            list-style-type: none;">
                                <li style="display: inline-block;
                                margin-right: 15px;
                                font-size: 15px;">June 23, 2022</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/video-page">Video 3</a></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div>
                        <a routerLink="/video-page"><img src="assets/img/blog-image/blog1.jpg"
                                alt="image"></a>
                    </div>
                    <div style="padding: 25px;">
                        <div>
                            <ul style="padding-left: 0;
                            margin-bottom: 0;
                            list-style-type: none;">
                                <li style="display: inline-block;
                                margin-right: 15px;
                                font-size: 15px;">June 20, 2022</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/video-page">Video 4</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Start Value Trade Area -->

<div style="background-color: #093032;">
    <div class="container">
        <!-- <div class="row align-items-center"> -->

        <div class="row justify-content-center align-items-center">
            <div class="col-lg-2 col-md-12 d-none d-sm-block">
                <div class="text-center">
                    <img src="assets/img/banner-image/logo1.png"
                        class="img-fluid"
                        alt="image">
                </div>
            </div>
            <div class="col-lg-8 col-md-12"
                style="padding-top: 10px;">
                <div class="text-center">
                    <h3 style="color: #fff; font-family: 'Jameel Noori Nastaleeq';">الجامعة الاحمدية السنية العالية
                        (الكامل) </h3>
                    <h3 style="color: #fff; font-family: 'Kalpurush';">জামেয়া আহমদিয়া সুন্নিয়া কামিল মাদরাসা</h3>
                    <h3 style="color: #fff; font-family: 'Congenial';">Jamea Ahmadia Sunnia Kamil Madrasah</h3>

                </div>
            </div>
            <div class="col-lg-2 col-md-12 d-none d-sm-block">
                <img src="assets/img/banner-image/header.jpeg"
                    alt="image"
                    style="height: 80%; width: 80%;">
            </div>
        </div>

        <!-- <div class="col-lg-3">
                <div class="contact-btn">
                    <a routerLink="/contact"
                        class="default-btn">
                        Contact Us <span></span>
                    </a>
                </div>
            </div>

            <div class="col-lg-9"
                style="color: #fff;">
                <span class="sub-title">READY TO DO THIS</span>
                <h2>Let's get to work!</h2>
            </div> -->

        <!-- </div> -->
    </div>
</div>
<!-- 
<div class="value-trade-area">
    <div class="container-fluid">
        <div class="value-trade-slides owl-carousel owl-theme">
            <div class="value-trade-card">
                <img src="assets/img/cryptocurrency-home/value-trade/REN.png"
                    alt="image">
                <p><b>RenBridges (REN)</b> $0.00475688 <span><i class='bx bx-up-arrow-alt'></i> +0.017%</span></p>
            </div>

            <div class="value-trade-card">
                <img src="assets/img/cryptocurrency-home/value-trade/BTC.png"
                    alt="image">
                <p><b>Bitcoin (BTC)</b> $0.00475688 <span><i class='bx bx-up-arrow-alt'></i> +0.017%</span></p>
            </div>

            <div class="value-trade-card">
                <img src="assets/img/cryptocurrency-home/value-trade/layer.png"
                    alt="image">
                <p><b>Yearn (YFI)</b> $0.00475688 <span class="color-two"><i class='bx bx-down-arrow-alt'></i>
                        +0.017%</span></p>
            </div>

            <div class="value-trade-card">
                <img src="assets/img/cryptocurrency-home/value-trade/USDT.png"
                    alt="image">
                <p><b>Tether (USDT)</b> $0.00475688 <span class="color-two"><i class='bx bx-down-arrow-alt'></i>
                        +0.017%</span></p>
            </div>

            <div class="value-trade-card">
                <img src="assets/img/cryptocurrency-home/value-trade/ETH.png"
                    alt="image">
                <p><b>Ethereum (ETH)</b> $0.00475688 <span><i class='bx bx-up-arrow-alt'></i> +0.017%</span></p>
            </div>

            <div class="value-trade-card">
                <img src="assets/img/cryptocurrency-home/value-trade/SNX.png"
                    alt="image">
                <p><b>Synthetix (Snx)</b> $0.00475688 <span><i class='bx bx-up-arrow-alt'></i> +0.017%</span></p>
            </div>
        </div>
    </div>
</div>
 -->
<!-- End Value Trade Area -->

<!-- Start Navbar Area -->
<div class="navbar-area navbar-area-with-position-relative"
    style="font-family: 'Congenial';">
    <div class="karlson-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-lg navbar-light">
                <!-- <a class="navbar-brand"
                    routerLink="/"><img src="assets/img/logo.png"
                        alt="logo"></a> -->
                <div *ngIf="scrolled == 0">

                </div>
                <a class="navbar-brand"
                    *ngIf="scrolled == 1"
                    routerLink="/"><img src="assets/img/banner-image/logo1.png"
                        height="60"
                        width="60"
                        alt="logo"></a>
                <button class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse mean-menu"
                    id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a routerLink="/"
                                routerLinkActive="active"
                                class="nav-link"
                                style="margin-right: 0;">Home</a>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle"
                                href="javascript:void(0)"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                style="margin-right: 0;">
                                About <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/at-a-glance"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}"
                                        class="nav-link">At a glance</a>
                                </li>
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/history"
                                        routerLinkActive="active"
                                        class="nav-link">History</a>
                                </li>
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/mission-and-vision"
                                        routerLinkActive="active"
                                        class="nav-link">Mission and Vision </a>
                                </li>
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/infrastructure"
                                        routerLinkActive="active"
                                        class="nav-link">Infrastructure</a>
                                </li>
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/achievement"
                                        routerLinkActive="active"
                                        class="nav-link">Achievement</a>
                                </li>
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/news-and-events"
                                        routerLinkActive="active"
                                        class="nav-link">News & Events</a>
                                </li>

                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle"
                                href="javascript:void(0)"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                style="margin-right: 0;">
                                Administration <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/governing-body"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}"
                                        class="nav-link">Governing Body</a>
                                </li>
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/chairman-message"
                                        routerLinkActive="active"
                                        class="nav-link">Chairman Message</a>
                                </li>
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/principal-message"
                                        routerLinkActive="active"
                                        class="nav-link">Principal Message</a>
                                </li>
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/teacher"
                                        routerLinkActive="active"
                                        class="nav-link">Teacher</a>
                                </li>
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/office-staff"
                                        routerLinkActive="active"
                                        class="nav-link">Office Staff</a>
                                </li>

                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle"
                                href="javascript:void(0)"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                style="margin-right: 0;">
                                Academic <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle"
                                        href="javascript:void(0)"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        style="margin-right: 0;">
                                        Departments <i class="fas fa-chevron-down"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"
                                            data-toggle="collapse"
                                            data-target=".navbar-collapse.show">
                                            <a routerLink="/hefz"
                                                routerLinkActive="active"
                                                class="nav-link">Hefz</a>
                                        </li>
                                        <li class="nav-item"
                                            data-toggle="collapse"
                                            data-target=".navbar-collapse.show">
                                            <a routerLink="/ebtedayee"
                                                routerLinkActive="active"
                                                class="nav-link">Ebtedayee</a>
                                        </li>
                                        <li class="nav-item"
                                            data-toggle="collapse"
                                            data-target=".navbar-collapse.show">
                                            <a routerLink="/dakhil"
                                                routerLinkActive="active"
                                                class="nav-link">Dakhil</a>
                                        </li>
                                        <li class="nav-item"
                                            data-toggle="collapse"
                                            data-target=".navbar-collapse.show">
                                            <a routerLink="/alim"
                                                routerLinkActive="active"
                                                class="nav-link">Alim</a>
                                        </li>
                                        <li class="nav-item"
                                            data-toggle="collapse"
                                            data-target=".navbar-collapse.show">
                                            <a routerLink="/fazil"
                                                routerLinkActive="active"
                                                class="nav-link">Fazil</a>
                                        </li>
                                        <li class="nav-item"
                                            data-toggle="collapse"
                                            data-target=".navbar-collapse.show">
                                            <a routerLink="/honours"
                                                routerLinkActive="active"
                                                class="nav-link">Honours</a>
                                        </li>
                                        <li class="nav-item"
                                            data-toggle="collapse"
                                            data-target=".navbar-collapse.show">
                                            <a routerLink="/kamil"
                                                routerLinkActive="active"
                                                class="nav-link">Kamil</a>
                                        </li>
                                        <li class="nav-item"
                                            data-toggle="collapse"
                                            data-target=".navbar-collapse.show">
                                            <a routerLink="/masters"
                                                routerLinkActive="active"
                                                class="nav-link">Masters</a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/academic-calendar"
                                        routerLinkActive="active"
                                        class="nav-link">Academic Calendar</a>
                                </li>
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/class-routine"
                                        routerLinkActive="active"
                                        class="nav-link">Class Routine</a>
                                </li>
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/syllabus-and-booklist"
                                        routerLinkActive="active"
                                        class="nav-link">Syllabus & Booklist</a>
                                </li>
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/notice"
                                        routerLinkActive="active"
                                        class="nav-link">Notice</a>
                                </li>
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/public-exam-result"
                                        routerLinkActive="active"
                                        class="nav-link">Public Exam Result</a>
                                </li>
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/academic-rules"
                                        routerLinkActive="active"
                                        class="nav-link">Academic Rules</a>
                                </li>

                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle"
                                href="javascript:void(0)"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                style="margin-right: 0;">
                                Admission <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/admission-circular"
                                        routerLinkActive="active"
                                        class="nav-link">Admission Circular</a>
                                </li>
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/prospectus"
                                        routerLinkActive="active"
                                        class="nav-link">Prospectus</a>
                                </li>
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/admission-form"
                                        routerLinkActive="active"
                                        class="nav-link">Admission Form</a>
                                </li>
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/download-admit-card"
                                        routerLinkActive="active"
                                        class="nav-link">Download Admit Card</a>
                                </li>
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/admission-result"
                                        routerLinkActive="active"
                                        class="nav-link">Admission Result</a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle"
                                href="javascript:void(0)"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                style="margin-right: 0;">
                                Facilities <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/classroom"
                                        routerLinkActive="active"
                                        class="nav-link">Classroom</a>
                                </li>
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/computer-lab"
                                        routerLinkActive="active"
                                        class="nav-link">Computer Lab</a>
                                </li>
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/science-lab"
                                        routerLinkActive="active"
                                        class="nav-link">Science Lab</a>
                                </li>
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/library"
                                        routerLinkActive="active"
                                        class="nav-link">Library</a>
                                </li>
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/hostel"
                                        routerLinkActive="active"
                                        class="nav-link">Hostel</a>
                                </li>
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/play-ground"
                                        routerLinkActive="active"
                                        class="nav-link">Play Ground</a>
                                </li>
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/canteen"
                                        routerLinkActive="active"
                                        class="nav-link">Canteen</a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle"
                                href="javascript:void(0)"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                style="margin-right: 0;">
                                Co-Curriculum <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle"
                                        href="javascript:void(0)"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        style="margin-right: 0;">
                                        Extra Co-Curriculum <i class="fas fa-chevron-down"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"
                                            data-toggle="collapse"
                                            data-target=".navbar-collapse.show">
                                            <a routerLink="/quiz-competition"
                                                routerLinkActive="active"
                                                class="nav-link">Quiz Competition</a>
                                        </li>
                                        <li class="nav-item"
                                            data-toggle="collapse"
                                            data-target=".navbar-collapse.show">
                                            <a routerLink="/qirat-competition"
                                                routerLinkActive="active"
                                                class="nav-link">Qirat Competition</a>
                                        </li>
                                        <li class="nav-item"
                                            data-toggle="collapse"
                                            data-target=".navbar-collapse.show">
                                            <a routerLink="/hamad-naat-competition"
                                                routerLinkActive="active"
                                                class="nav-link">Hamad-Naat Competition</a>
                                        </li>
                                        <li class="nav-item"
                                            data-toggle="collapse"
                                            data-target=".navbar-collapse.show">
                                            <a routerLink="/debate-competition"
                                                routerLinkActive="active"
                                                class="nav-link">Debate Competition</a>
                                        </li>
                                        <li class="nav-item"
                                            data-toggle="collapse"
                                            data-target=".navbar-collapse.show">
                                            <a routerLink="/essay-competition"
                                                routerLinkActive="active"
                                                class="nav-link">Essay Competition</a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/scout"
                                        routerLinkActive="active"
                                        class="nav-link">Scout</a>
                                </li>
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/sports-and-game"
                                        routerLinkActive="active"
                                        class="nav-link">Sports & Game</a>
                                </li>

                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle"
                                href="javascript:void(0)"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                style="margin-right: 0;">
                                Gallery <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/photo"
                                        routerLinkActive="active"
                                        class="nav-link">Photo</a>
                                </li>
                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a routerLink="/video-page"
                                        routerLinkActive="active"
                                        class="nav-link">Video</a>
                                </li>

                            </ul>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/e-payment"
                                routerLinkActive="active"
                                class="nav-link"
                                style="margin-right: 0;">E-payment System</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/contact"
                                routerLinkActive="active"
                                class="nav-link"
                                style="margin-right: 0;">Contact</a>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle"
                                href="javascript:void(0)"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                style="margin-right: 0;">
                                {{selectedLanguage.name}} <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a [routerLink]=""
                                        (click)="setLanguage('en')"
                                        routerLinkActive="active"
                                        class="nav-link"
                                        class="nav-link">English</a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]=""
                                        (click)="setLanguage('bn')"
                                        routerLinkActive="active"
                                        class="nav-link"
                                        class="nav-link">বাংলা</a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]=""
                                        (click)="setLanguage('ar')"
                                        routerLinkActive="active"
                                        class="nav-link"
                                        class="nav-link">العربية</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <!-- <div class="others-options">
                        <div class="option-item"><i class="search-btn flaticon-search"></i>
                            <i class="close-btn flaticon-close"></i>
                            <div class="search-overlay search-popup">
                                <div class='search-box'>
                                    <form class="search-form">
                                        <input class="search-input"
                                            name="search"
                                            placeholder="Search"
                                            type="text">
                                        <button class="search-button"
                                            type="submit"><i class="fas fa-search"></i></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="burger-menu">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div> -->
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->

<app-navbar-style-two-2></app-navbar-style-two-2>

<section class="blog-details-area pb-100"
    style="
background-image: url('assets/img/banner-image/body2.png');  
background-size: 100% ;  

background-repeat: repeat-y;
padding-top: 20px;
font-family: 'Congenial';">
    <div class="container"
        style="background: transparent;">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc"
                    style="background-color: #FFFFFF; padding: 20px; border: 1px solid #eeeeee;"
                    *ngFor="let item of dummyItems">
                    <div class="container">
                        <div class="row align-items-center">
                            <h4>{{item.title}}</h4>

                            <div class="col-lg-5 col-md-12">
                                <div class="about-image">
                                    <img [src]="item.image"
                                        alt="image">
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-12">
                                <div>

                                    <div>

                                        <p>{{item.date}}</p>
                                        <p class="elip">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                                            doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
                                            veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                                            <a routerLink="/achievement">Read more</a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area"
                    id="secondary">

                    <section class="widget widget_karlson_posts_thumb"
                        style="background-color: #FFFFFF; padding: 20px; padding-top: 0; border: 1px solid #eeeeee;">

                        <div class="page-title-content"
                            style="margin-top: 0; text-align: left;">
                            <ul>
                                <li>
                                    <a routerLink="/">
                                        <i class="fa-solid fa-house"></i>
                                    </a>
                                </li>
                                <li>About</li>
                                <li>News & Events</li>
                            </ul>
                        </div>

                    </section>

                    <section class="widget widget_karlson_posts_thumb"
                        style="background-color: #FFFFFF; padding: 20px; border: 1px solid #eeeeee; margin-top: 15px;">
                        <h3 class="widget-title">Suggested Video</h3>

                        <iframe width="100%"
                            height="200"
                            src="https://www.youtube.com/embed/MAz9kRnvSYE"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen=""></iframe>

                    </section>

                    <section class="widget widget_categories"
                        style="background-color: #FFFFFF; padding: 20px; border: 1px solid #eeeeee; margin-top: 15px;">
                        <h3 class="widget-title">Related Topics</h3>

                        <ul>
                            <li>
                                <a routerLink="/at-a-glance">At a glance</a>
                            </li>
                            <li>
                                <a routerLink="/history">History</a>
                            </li>
                            <li>
                                <a routerLink="/mission-and-vision">Mission and Vision </a>
                            </li>
                            <li>
                                <a routerLink="/infrastructure">Infrastructure</a>
                            </li>
                            <li>
                                <a routerLink="/achievement">Achievement</a>
                            </li>
                            <li>
                                <a routerLink="/news-and-events">News & Events</a>
                            </li>
                        </ul>

                    </section>

                </aside>
            </div>
        </div>
    </div>
</section>

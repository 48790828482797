<app-navbar-style-two-2></app-navbar-style-two-2>

<section class="blog-details-area pb-100"
    style="
background-image: url('assets/img/banner-image/body2.png');  
background-size: 100% ;  

background-repeat: repeat-y;
padding-top: 20px;
font-family: 'Congenial';">
    <div class="container"
        style="background: transparent;">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="machine-learning-slider owl-carousel owl-theme"
                        style="background-color: #FFFFFF; padding: 20px; border: 1px solid #eeeeee;">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="container mt-80">
                                    <div class="article-image">
                                        <img src="assets/img/blog-image/sl-01.jpg"
                                            alt="image">
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="container mt-80">
                                    <div class="article-image">
                                        <img src="assets/img/blog-image/sl-02.jpg"
                                            alt="image">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="container mt-80">
                                    <div class="article-image">
                                        <img src="assets/img/blog-image/sl-03.jpg"
                                            alt="image">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="container mt-80">
                                    <div class="article-image">
                                        <img src="assets/img/blog-image/g4.jpg"
                                            alt="image">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="container mt-80">
                                    <div class="article-image">
                                        <img src="assets/img/blog-image/g8.jpg"
                                            alt="image">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="container mt-80">
                                    <div class="article-image">
                                        <img src="assets/img/blog-image/g10.jpg"
                                            alt="image">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="container mt-80">
                                    <div class="article-image">
                                        <img src="assets/img/blog-image/g12.jpg"
                                            alt="image">
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="article-content"
                        style="background-color: #FFFFFF; padding: 20px; border: 1px solid #eeeeee; ">

                        <h3 style="border-bottom: 2px solid #eeeeee; line-height: 2.2;"
                            [ngStyle]="{'font-family': GetFontName()}">
                            {{ 'COMMON.WELCOME' | translate }}</h3>

                        <p style="font-family: 'Congenial';">All praise is due to The Almighty Allah. Millions of
                            duroods and salutations to the beloved
                            Prophet (peace and blessings of Allah be upon him). Education is the backbone of the nation.
                            Especially getting religious education is very important for human life. In 1954, one of the
                            spiritual saints of the subcontinent, Qutbul Auliya, Hadi Din and Millat, Rahnumae Shariat
                            and Tariqat, Peshwa-e-Ahle Sunnat Hazratul Allama Hafez Qari Syed Ahmad Shah Sirikoti (R.A.)
                            established The Jamia Ahmadia Sunnia Kamil Madrasa. We are Huzur Qiblah Shahenshahe Sirikoti
                            (R.A.) - Grateful to him.</p>
                        <p style="font-family: 'Congenial';">This madrasa has been playing a leading role in
                            establishing Islam and Sunniyat at home and
                            abroad. Ghaus-e-Zaman Al-Rasul Allama Hafiz Qari Sayyid Muhammad Tayyab Shah (R.A.) Under
                            its patronage, the reputation and reputation of this madrasa has spread all over the world.
                            At present, all the activities of Allama Syed Muhammad Taher Shah (Madda Zilluhul Ali), one
                            of the emperors of the spiritual world of Huzur Qiblah Shaykh Fa'al, and Hazrat Allama Pir
                            Syed Muhammad Sabir Shah (Madda Zilluhul Ali) and The Masha'ikh of Silsila are moving
                            towards a much faster pace.</p>
                        <p style="font-family: 'Congenial';">That's why millions of pigs are in the court of Allah.
                            Alhamdulillah. Congratulations to
                            those whose sacrifices and deeds have made this madrasa a place in the hearts of Sunni
                            Muslims of the world today. I wish the students and teachers of the madrasa, well-wishers,
                            Arakine Anjuman, the board of directors of the madrasa, the Gausiya Committee and all for
                            the welfare and success of the two worlds. Amin ya Rabbal Alamin, Bihurmati Sayyidil
                            Mursalin wa Alihi wa Ashabihi Azmain.</p>

                    </div>

                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area"
                    id="secondary">

                    <section class="widget widget_karlson_posts_thumb"
                        style="background-color: #FFFFFF; padding: 20px; border: 1px solid #eeeeee;">
                        <h3 class="widget-title">Chairman's Message</h3>

                        <article class="item">
                            <a routerLink="#"
                                class="thumb">
                                <span class="fullimage cover"
                                    style="background-image: url('assets/img/banner-image/Professor-Muhammad-Didarul-Islam.jpg');"
                                    role="img"></span>
                            </a>
                            <div class="info">
                                <h5><a routerLink="/chairman-message">Professor-Muhammad-Didarul-Islam.jpg</a></h5>
                                <h6><a routerLink="/chairman-message">Chairman's Message</a></h6>
                            </div>
                            <div class="clear"></div>
                        </article>

                    </section>

                    <section class="widget widget_karlson_posts_thumb"
                        style="background-color: #FFFFFF; padding: 20px; border: 1px solid #eeeeee;">
                        <h3 class="widget-title">Principal's Message</h3>

                        <article class="item">
                            <a routerLink="#"
                                class="thumb">
                                <span class="fullimage cover"
                                    style="background-image: url('assets/img/banner-image/Dr-Muhammad-Liaquat-Ali.jpg');"
                                    role="img"></span>
                            </a>
                            <div class="info">
                                <h5><a routerLink="/principal-message">Dr. Muhammad Liaquat Ali</a></h5>
                                <h6><a routerLink="/principal-message">Principal's Message</a></h6>
                            </div>
                            <div class="clear"></div>
                        </article>

                    </section>

                </aside>
            </div>
        </div>
    </div>
</section>

<app-navbar-style-two-2></app-navbar-style-two-2>

<section class="blog-details-area pb-100"
    style="
background-image: url('assets/img/banner-image/body2.png');  
background-size: 100% ;  

background-repeat: repeat-y;
padding-top: 20px;
font-family: 'Congenial';">
    <div class="container"
        style="background: transparent;">

        <div class="row justify-content-center align-items-center">

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div style="margin-bottom: 30px;
                box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);">

                    <div style="padding: 15px;
                    position: relative;
                    text-align: center;
                    background-color: #FFFFFF;">
                        <h3>Governing Body</h3>
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center align-items-center">

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div style="margin-bottom: 30px;
                box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);">
                    <div class="image">
                        <img src="assets/img/banner-image/Professor-Muhammad-Didarul-Islam.jpg"
                            alt="image"
                            style="height: 356px; width: 356px;">
                    </div>
                    <div style="padding: 25px;
                    position: relative;
                    text-align: center;
                    background-color: #FFFFFF;">
                        <h3 style="transition: none;">Professor Muhammad Didarul Islam</h3>
                        <span style="transition: none;">Chairman</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center align-items-center">

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div style="margin-bottom: 30px;
                box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);">
                    <div class="image">
                        <img src="assets/img/banner-image/Dr-Muhammad-Liaquat-Ali.jpg"
                            alt="image"
                            style="height: 356px; width: 356px;">
                    </div>
                    <div style="padding: 25px;
                    position: relative;
                    text-align: center;
                    background-color: #FFFFFF;">
                        <h3 style="transition: none;">Dr. Muhammad Liaquat Ali</h3>
                        <span style="transition: none;">Principal</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Start Footer Area -->
<!-- <section class="footer-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contact Info</h3>
                    <ul class="footer-contact-info">
                        <li>
                            <i class="flaticon-phone-call"></i>
                            <span>Mon to Fri : 10:00AM - 06:00PM</span>
                            <a href="tel:+1235421457852">+123 54214 578 52</a>
                        </li>
                        <li>
                            <i class="flaticon-email"></i>
                            <span>Do You Have a Question?</span>
                            <a href="mailto:hello@karlson.com">hello@karlson.com</a>
                        </li>
                        <li>
                            <i class="flaticon-social-media"></i>
                            <span>Socials Network</span>
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-linkedin"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Quick Links</h3>
                    <ul class="footer-quick-links">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/">About</a></li>
                        <li><a routerLink="/">Blog</a></li>
                        <li><a routerLink="/">Contact</a></li>
                        <li><a routerLink="/">Agency</a></li>
                        <li><a routerLink="/">Digital</a></li>
                        <li><a routerLink="/">Support</a></li>
                        <li><a routerLink="/">Shop</a></li>
                        <li><a routerLink="/">Agency</a></li>
                        <li><a routerLink="/">Digital</a></li>
                        <li><a routerLink="/">Support</a></li>
                        <li><a routerLink="/">Shop</a></li>
                        <li><a routerLink="/">Digital</a></li>
                        <li><a routerLink="/">Support</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Instagram</h3>
                    <ul class="footer-instagram-post">
                        <li><a href="#" target="_blank">
                            <img src="assets/img/instagram-image/instagram1.jpg" alt="image">
                        </a></li>
                        <li><a href="#" target="_blank">
                            <img src="assets/img/instagram-image/instagram2.jpg" alt="image">
                        </a></li>
                        <li><a href="#" target="_blank">
                            <img src="assets/img/instagram-image/instagram3.jpg" alt="image">
                        </a></li>
                        <li><a href="#" target="_blank">
                            <img src="assets/img/instagram-image/instagram4.jpg" alt="image">
                        </a></li>
                        <li><a href="#" target="_blank">
                            <img src="assets/img/instagram-image/instagram5.jpg" alt="image">
                        </a></li>
                        <li><a href="#" target="_blank">
                            <img src="assets/img/instagram-image/instagram6.jpg" alt="image">
                        </a></li>
                        <li><a href="#" target="_blank">
                            <img src="assets/img/instagram-image/instagram7.jpg" alt="image">
                        </a></li>
                        <li><a href="#" target="_blank">
                            <img src="assets/img/instagram-image/instagram8.jpg" alt="image">
                        </a></li>
                        <li><a href="#" target="_blank">
                            <img src="assets/img/instagram-image/instagram1.jpg" alt="image">
                        </a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6 col-md-6">
                    <p>Copyright @{{currentYear}} <a href="https://envytheme.com/" target="_blank">EnvyTheme</a>. All rights reserved</p>
                </div>
                <div class="col-lg-6 col-sm-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Terms & Conditions</a></li>
                        <li><a routerLink="/">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Footer Area -->

<section class="footer-area"
    style="padding-top: 20px; font-family: 'Congenial';">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">

                    <h3>Quick Links</h3>
                    <ul class="footer-quick-links"
                        style="display: block;">
                        <li style="max-width: fit-content;"><a target="_blank"
                                href="http://www.moedu.gov.bd/">Ministry of
                                Education</a></li>
                        <li style="max-width: fit-content;"><a target="_blank"
                                href="http://www.educationboard.gov.bd/
                            ">Education Board</a></li>
                        <li style="max-width: fit-content;"><a target="_blank"
                                href="http://bmeb.gov.bd/">Bangladesh Madrasah
                                Education Board</a></li>
                        <li style="max-width: fit-content;"><a target="_blank"
                                href="http://www.ugc.gov.bd/">University Grants
                                Commission of Bangladesh</a></li>
                        <li style="max-width: fit-content;"><a target="_blank"
                                href="http://banbeis.gov.bd/new/">Banbeis</a></li>
                    </ul>

                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Socials Links</h3>
                    <ul class="footer-contact-info">
                        <!-- <li>
                            <i class="flaticon-phone-call"></i>
                            <span>Mon to Fri : 10:00AM - 06:00PM</span>
                            <a href="tel:+1235421457852">+123 54214 578 52</a>
                        </li>
                        <li>
                            <i class="flaticon-email"></i>
                            <span>Do You Have a Question?</span>
                            <a href="mailto:hello@karlson.com">hello@karlson.com</a>
                        </li> -->
                        <li style="padding-left: 0;">
                            <!-- <i class="flaticon-social-media"></i> -->
                            <!-- <span>Socials Network</span> -->
                            <ul class="social">
                                <!-- <li><a href="#"
                                        target="_blank"><i class="fab fa-twitter"></i></a></li> -->
                                <li><a href="https://www.facebook.com/jaskmofficials"
                                        target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <!-- <li><a href="#"
                                        target="_blank"><i class="fab fa-instagram"></i></a></li> -->
                                <!-- <li><a href="#"
                                        target="_blank"><i class="fab fa-linkedin"></i></a></li> -->
                                <li><a href="https://www.youtube.com/@jaskmofficial"
                                        target="_blank"><i class="fab fa-youtube"></i></a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Our Address</h3>
                    <!-- <ul class="footer-instagram-post">
                        <li><a href="#"
                                target="_blank">
                                <img src="assets/img/instagram-image/instagram1.jpg"
                                    alt="image">
                            </a></li>
                        <li><a href="#"
                                target="_blank">
                                <img src="assets/img/instagram-image/instagram2.jpg"
                                    alt="image">
                            </a></li>
                        <li><a href="#"
                                target="_blank">
                                <img src="assets/img/instagram-image/instagram3.jpg"
                                    alt="image">
                            </a></li>
                        <li><a href="#"
                                target="_blank">
                                <img src="assets/img/instagram-image/instagram4.jpg"
                                    alt="image">
                            </a></li>
                        <li><a href="#"
                                target="_blank">
                                <img src="assets/img/instagram-image/instagram5.jpg"
                                    alt="image">
                            </a></li>
                        <li><a href="#"
                                target="_blank">
                                <img src="assets/img/instagram-image/instagram6.jpg"
                                    alt="image">
                            </a></li>
                        <li><a href="#"
                                target="_blank">
                                <img src="assets/img/instagram-image/instagram7.jpg"
                                    alt="image">
                            </a></li>
                        <li><a href="#"
                                target="_blank">
                                <img src="assets/img/instagram-image/instagram8.jpg"
                                    alt="image">
                            </a></li>
                        <li><a href="#"
                                target="_blank">
                                <img src="assets/img/instagram-image/instagram1.jpg"
                                    alt="image">
                            </a></li>
                    </ul> -->

                    <ul class="footer-contact-info">
                        <li style="padding-left: 0;">
                            <!-- <i class="flaticon-phone-call"></i> -->
                            <span>Sholashahar, Post: Chandgaon-4212, Panchlaish, Chattagram, Bangladesh. <br>Phone:
                                01309-104702</span>
                            <!-- <a href="tel:+1235421457852">+123 54214 578 52</a> -->
                        </li>

                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright-area"
            style="margin-top: 0; padding-top: 15px; padding-bottom: 15px;">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6 col-md-6">
                    <p>Copyright @{{currentYear}} <a href="https://deshuniversal.com/"
                            target="_blank">Desh Unidersal Private Limited</a>. All rights reserved</p>
                </div>
                <div class="col-lg-6 col-sm-6 col-md-6">
                    <ul>
                        <!-- <li><a routerLink="/">Terms & Conditions</a></li>
                        <li><a routerLink="/">Privacy Policy</a></li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<app-navbar-style-two-2></app-navbar-style-two-2>

<section class="blog-details-area pb-100"
    style="
background-image: url('assets/img/banner-image/body2.png');  
background-size: 100% ;  

background-repeat: repeat-y;
padding-top: 20px;
font-family: 'Congenial';">
    <div class="container"
        style="background: transparent;">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc"
                    style="background-color: #FFFFFF; padding: 20px; border: 1px solid #eeeeee;">
                    <div class="article-image">
                        <h2>
                            Chairman's Message
                        </h2>
                        <img src="assets/img/banner-image/Professor-Muhammad-Didarul-Islam.jpg"
                            alt="image">
                    </div>
                    <div class="article-content">

                        <p>
                            The traditional Jamea Ahmadia Sunnia Kamil Madrasah has different characteristics. In
                            addition to acquiring Islamic knowledge, there is also a system of acquiring leadership
                            qualities, qualifications and skills of the students. This madrasa has been playing a unique
                            role in spreading the ideology of Ahle Sunnat Wal Jama'at. Therefore, this madrasa was
                            upgraded to one of the leading deeni educational institutions in the country. Students have
                            been acquiring knowledge here in an environment of sincere teaching by qualified teachers
                            under the guidance of skilled principals and vice-principals. New students will be able to
                            develop themselves by utilizing this environment. I wish the teachers and students to always
                            strive for their respective responsibilities and duties to protect the tradition of the
                            madrasa.
                        </p>
                        <div class="row">

                            <div class="col-lg-6 col-md-12">

                            </div>
                            <div class="col-lg-6 col-md-12">

                                <p>
                                    Professor Muhammad Didarul Islam
                                    <br>
                                    Chairman
                                    <br>
                                    Jamea Ahmadia Sunnia Kamil Madrasah
                                </p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area"
                    id="secondary">

                    <section class="widget widget_karlson_posts_thumb"
                        style="background-color: #FFFFFF; padding: 20px; padding-top: 0; border: 1px solid #eeeeee;">

                        <div class="page-title-content"
                            style="margin-top: 0; text-align: left;">
                            <ul>
                                <li>
                                    <a routerLink="/">
                                        <i class="fa-solid fa-house"></i>
                                    </a>
                                </li>
                                <li>Administration</li>
                                <li>Chairman's Message</li>
                            </ul>
                        </div>

                    </section>

                    <section class="widget widget_karlson_posts_thumb"
                        style="background-color: #FFFFFF; padding: 20px; border: 1px solid #eeeeee; margin-top: 15px;">
                        <h3 class="widget-title">Suggested Video</h3>

                        <iframe width="100%"
                            height="200"
                            src="https://www.youtube.com/embed/MAz9kRnvSYE"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen=""></iframe>

                    </section>

                    <section class="widget widget_categories"
                        style="background-color: #FFFFFF; padding: 20px; border: 1px solid #eeeeee; margin-top: 15px;">
                        <h3 class="widget-title">Related Topics</h3>

                        <ul>
                            <li>
                                <a routerLink="/governing-body">Governing Body</a>
                            </li>
                            <li>
                                <a routerLink="/chairman-message">Chairman Message</a>
                            </li>
                            <li>
                                <a routerLink="/principal-message">Principal Message</a>
                            </li>
                            <li>
                                <a routerLink="/teacher">Teacher</a>
                            </li>
                            <li>
                                <a routerLink="/office-staff">Office Staff</a>
                            </li>
                        </ul>

                    </section>

                </aside>
            </div>
        </div>
    </div>
</section>

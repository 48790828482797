import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HomeTranslationLoaderService } from 'src/services/home-translation-loader.service';
import { arabic, bangla, english } from 'src/services/locale';
import * as _ from 'lodash';

@Component({
  selector: 'app-navbar-style-two-2',
  templateUrl: './navbar-style-two-2.component.html',
  styleUrls: ['./navbar-style-two-2.component.scss']
})
export class NavbarStyleTwo2Component implements OnInit
{

  scrolled = 0;
  selectedLanguage: any;
  selectedLanguageName: any;
  languages: any;

  constructor(
    private _translateService: TranslateService,
    private _homeTranslationLoaderService: HomeTranslationLoaderService,
  )
  {
    this.languages = [
      {
        name: 'English',
        id: 'en',
      },
      {
        name: 'বাংলা',
        id: 'bn',
      },
      {
        name: 'العربية',
        id: 'ar',
      }
    ];


    this._homeTranslationLoaderService.loadTranslations(english, bangla, arabic);
  }

  ngOnInit(): void
  {
    this.selectedLanguage = _.find(this.languages, { id: this._translateService.currentLang });

    let localCulture = localStorage.getItem('selectedLanguage');

    if (localCulture !== 'undefined')
    {
      this._translateService.use(localCulture);
      this.selectedLanguage = _.find(this.languages, { id: localCulture });
    }
    else
    {
      localStorage.setItem('selectedLanguage', this._translateService.currentLang);
    }


  }

  setLanguage(lang): void
  {
    // Set the selected language for the toolbar
    this.selectedLanguage = _.find(this.languages, { id: lang });

    if (localStorage.getItem('selectedLanguage') != null)
    {
      localStorage.removeItem('selectedLanguage');
    }
    localStorage.setItem('selectedLanguage', lang);
    // Use the selected language for translations
    this._translateService.use(lang);
    window.location.reload();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event)
  {
    const numb = window.scrollY;
    if (numb >= 100)
    {
      this.scrolled = 1;
    }
    else
    {
      this.scrolled = 0;
    }

  }
}

<app-navbar-style-two-2></app-navbar-style-two-2>

<section class="blog-details-area pb-100"
    style="
background-image: url('assets/img/banner-image/body2.png');  
background-size: 100% ;  

background-repeat: repeat-y;
padding-top: 20px;
font-family: 'Congenial';">
    <div class="container"
        style="background: transparent;">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc"
                    style="background-color: #FFFFFF; padding: 20px; border: 1px solid #eeeeee;">
                    <div class="form-content">
                        <div class="form-header">
                            <h3>E-payment System</h3>
                        </div>

                        <div class="form-header">
                            <h4>Form To Be Added</h4>
                        </div>

                        <!-- <form>
                            <div class="form-group">
                                <label>Applicant ID:</label>
                                <input type="text"
                                    class="form-control"
                                    id="ApplicantId"
                                    style="border: 2px solid #eee;">
                            </div>
                            <div class="form-group">
                                <label>Mobile No:</label>
                                <input type="number"
                                    class="form-control"
                                    id="MobileNo"
                                    style="border: 2px solid #eee;">
                            </div>

                            <button type="submit"
                                class="default-btn">View Admit Card</button>
                        </form> -->

                    </div>

                </div>
            </div>

        </div>
    </div>
</section>

<app-navbar-style-two-2></app-navbar-style-two-2>

<section class="blog-details-area pb-100"
    style="
background-image: url('assets/img/banner-image/body2.png');  
background-size: 100% ;  

background-repeat: repeat-y;
padding-top: 20px;
font-family: 'Congenial';">
    <div class="container"
        style="background: transparent;">
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-8 col-md-12">
                <div class="container">
                    <div class="row align-items-center text-center"
                        style="background-color: #FFFFFF; padding: 20px;">
                        <h3>Jamea Ahmadia Sunnia Kamil Madrasah - Office Staff</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc"
                    style="background-color: #FFFFFF; padding: 20px; border: 1px solid #eeeeee;"
                    *ngFor="let item of dummyItems">
                    <div class="container">
                        <div class="row align-items-center">

                            <div class="col-lg-5 col-md-12">
                                <div class="about-image">
                                    <img [src]="item.image"
                                        alt="image">
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-12">
                                <div class="row align-items-center">
                                    <div class="col-lg-6 col-md-12">

                                        <div>

                                            <span>Name: </span>

                                        </div>
                                        <div>

                                            <span>Designation: </span>

                                        </div>

                                        <div>

                                            <span>Joining Date: </span>

                                        </div>

                                        <div>

                                            <span>ID: </span>
                                        </div>

                                    </div>
                                    <div class="col-lg-6 col-md-12">

                                        <div>

                                            <span>{{item.name}} </span>

                                        </div>

                                        <div>

                                            <span>{{item.designation}} </span>

                                        </div>

                                        <div>

                                            <span>{{item.joiningDate}} </span>
                                        </div>

                                        <div>

                                            <span>{{item.id}} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</section>

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './components/pages/about/about.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServiceDetailsComponent } from './components/pages/service-details/service-details.component';
import { ProjectsComponent } from './components/pages/projects/projects.component';
import { ProjectDetailsComponent } from './components/pages/project-details/project-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { FeaturesComponent } from './components/pages/features/features.component';
import { TeamComponent } from './components/pages/team/team.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { PartnerComponent } from './components/pages/partner/partner.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { HomeDemoOneComponent } from './components/pages/home-demo-one/home-demo-one.component';
import { HomeDemoTwoComponent } from './components/pages/home-demo-two/home-demo-two.component';
import { HomeDemoThreeComponent } from './components/pages/home-demo-three/home-demo-three.component';
import { HomeDemoFourComponent } from './components/pages/home-demo-four/home-demo-four.component';
import { HomeDemoFiveComponent } from './components/pages/home-demo-five/home-demo-five.component';
import { HomeDemoSixComponent } from './components/pages/home-demo-six/home-demo-six.component';
import { HomeDemoSevenComponent } from './components/pages/home-demo-seven/home-demo-seven.component';
import { HomeDemoEightComponent } from './components/pages/home-demo-eight/home-demo-eight.component';
import { HomeDemoNineComponent } from './components/pages/home-demo-nine/home-demo-nine.component';
import { AboutStyleTwoComponent } from './components/pages/about-style-two/about-style-two.component';
import { TeamStyleTwoComponent } from './components/pages/team-style-two/team-style-two.component';
import { LoginComponent } from './components/pages/login/login.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { ServicesStyleTwoComponent } from './components/pages/services-style-two/services-style-two.component';
import { ServicesStyleThreeComponent } from './components/pages/services-style-three/services-style-three.component';
import { ProjectsStyleTwoComponent } from './components/pages/projects-style-two/projects-style-two.component';
import { ProjectsStyleThreeComponent } from './components/pages/projects-style-three/projects-style-three.component';
import { ProjectsStyleFourComponent } from './components/pages/projects-style-four/projects-style-four.component';
import { BlogRightSidebarComponent } from './components/pages/blog-right-sidebar/blog-right-sidebar.component';
import { HomeDemoTenComponent } from './components/pages/home-demo-ten/home-demo-ten.component';
import { HomeDemoElevenComponent } from './components/pages/home-demo-eleven/home-demo-eleven.component';
import { HomeDemoTwelveComponent } from './components/pages/home-demo-twelve/home-demo-twelve.component';
import { HomeDemoTen2Component } from './components/pages/home-demo-ten-2/home-demo-ten-2.component';
import { AtaGlanceComponent } from './components/pages/at-a-glance/at-a-glance.component';
import { HistoryComponent } from './components/pages/history/history.component';
import { MissionAndVisionComponent } from './components/pages/mission-and-vision/mission-and-vision.component';
import { InfrastructureComponent } from './components/pages/infrastructure/infrastructure.component';
import { AchievementComponent } from './components/pages/achievement/achievement.component';
import { NewsAndEventsComponent } from './components/pages/news-and-events/news-and-events.component';
import { GoverningBodyComponent } from './components/pages/governing-body/governing-body.component';
import { ChairmanMessageComponent } from './components/pages/chairman-message/chairman-message.component';
import { PrincipalMessageComponent } from './components/pages/principal-message/principal-message.component';
import { TeacherComponent } from './components/pages/teacher/teacher.component';
import { OfficeStaffComponent } from './components/pages/office-staff/office-staff.component';
import { AcademicCalendarComponent } from './components/pages/academic-calendar/academic-calendar.component';
import { ClassRoutineComponent } from './components/pages/class-routine/class-routine.component';
import { SyllabusAndBooklistComponent } from './components/pages/syllabus-and-booklist/syllabus-and-booklist.component';
import { NoticeComponent } from './components/pages/notice/notice.component';
import { PublicExamResultComponent } from './components/pages/public-exam-result/public-exam-result.component';
import { AcademicRulesComponent } from './components/pages/academic-rules/academic-rules.component';
import { HefzComponent } from './components/pages/hefz/hefz.component';
import { EbtedayeeComponent } from './components/pages/ebtedayee/ebtedayee.component';
import { DakhilComponent } from './components/pages/dakhil/dakhil.component';
import { AlimComponent } from './components/pages/alim/alim.component';
import { FazilComponent } from './components/pages/fazil/fazil.component';
import { HonoursComponent } from './components/pages/honours/honours.component';
import { KamilComponent } from './components/pages/kamil/kamil.component';
import { MastersComponent } from './components/pages/masters/masters.component';
import { AdmissionCircularComponent } from './components/pages/admission-circular/admission-circular.component';
import { ProspectusComponent } from './components/pages/prospectus/prospectus.component';
import { AdmissionFormComponent } from './components/pages/admission-form/admission-form.component';
import { AdmissionResultComponent } from './components/pages/admission-result/admission-result.component';
import { DownloadAdmitCardComponent } from './components/pages/download-admit-card/download-admit-card.component';
import { ClassroomComponent } from './components/pages/classroom/classroom.component';
import { ComputerLabComponent } from './components/pages/computer-lab/computer-lab.component';
import { ScienceLabComponent } from './components/pages/science-lab/science-lab.component';
import { LibraryComponent } from './components/pages/library/library.component';
import { HostelComponent } from './components/pages/hostel/hostel.component';
import { PlayGroundComponent } from './components/pages/play-ground/play-ground.component';
import { CanteenComponent } from './components/pages/canteen/canteen.component';
import { QuizCompetitionComponent } from './components/pages/quiz-competition/quiz-competition.component';
import { QiratCompetitionComponent } from './components/pages/qirat-competition/qirat-competition.component';
import { HamadNaatCompetitionComponent } from './components/pages/hamad-naat-competition/hamad-naat-competition.component';
import { DebateCompetitionComponent } from './components/pages/debate-competition/debate-competition.component';
import { EssayCompetitionComponent } from './components/pages/essay-competition/essay-competition.component';
import { ScoutComponent } from './components/pages/scout/scout.component';
import { SportsAndGameComponent } from './components/pages/sports-and-game/sports-and-game.component';
import { PhotoComponent } from './components/pages/photo/photo.component';
import { VideoPageComponent } from './components/pages/video-page/video-page.component';
import { EPaymentSystemComponent } from './components/pages/e-payment/e-payment.component';
import { ContactOComponent } from './components/pages/contact-o/contact-o.component';


const routes: Routes = [
    { path: '', component: HomeDemoTen2Component },
    { path: 'at-a-glance', component: AtaGlanceComponent },
    { path: 'history', component: HistoryComponent },
    { path: 'mission-and-vision', component: MissionAndVisionComponent },
    { path: 'infrastructure', component: InfrastructureComponent },
    { path: 'achievement', component: AchievementComponent },
    { path: 'news-and-events', component: NewsAndEventsComponent },
    { path: 'governing-body', component: GoverningBodyComponent },
    { path: 'chairman-message', component: ChairmanMessageComponent },
    { path: 'principal-message', component: PrincipalMessageComponent },
    { path: 'teacher', component: TeacherComponent },
    { path: 'office-staff', component: OfficeStaffComponent },
    { path: 'academic-calendar', component: AcademicCalendarComponent },
    { path: 'class-routine', component: ClassRoutineComponent },
    { path: 'syllabus-and-booklist', component: SyllabusAndBooklistComponent },
    { path: 'notice', component: NoticeComponent },
    { path: 'public-exam-result', component: PublicExamResultComponent },
    { path: 'academic-rules', component: AcademicRulesComponent },
    { path: 'hefz', component: HefzComponent },
    { path: 'ebtedayee', component: EbtedayeeComponent },
    { path: 'dakhil', component: DakhilComponent },
    { path: 'alim', component: AlimComponent },
    { path: 'fazil', component: FazilComponent },
    { path: 'honours', component: HonoursComponent },
    { path: 'kamil', component: KamilComponent },
    { path: 'masters', component: MastersComponent },
    { path: 'admission-circular', component: AdmissionCircularComponent },
    { path: 'prospectus', component: ProspectusComponent },
    { path: 'admission-form', component: AdmissionFormComponent },
    { path: 'admission-result', component: AdmissionResultComponent },
    { path: 'download-admit-card', component: DownloadAdmitCardComponent },
    { path: 'classroom', component: ClassroomComponent },
    { path: 'computer-lab', component: ComputerLabComponent },
    { path: 'science-lab', component: ScienceLabComponent },
    { path: 'library', component: LibraryComponent },
    { path: 'hostel', component: HostelComponent },
    { path: 'play-ground', component: PlayGroundComponent },
    { path: 'canteen', component: CanteenComponent },
    { path: 'quiz-competition', component: QuizCompetitionComponent },
    { path: 'qirat-competition', component: QiratCompetitionComponent },
    { path: 'hamad-naat-competition', component: HamadNaatCompetitionComponent },
    { path: 'debate-competition', component: DebateCompetitionComponent },
    { path: 'essay-competition', component: EssayCompetitionComponent },
    { path: 'scout', component: ScoutComponent },
    { path: 'sports-and-game', component: SportsAndGameComponent },
    { path: 'photo', component: PhotoComponent },
    { path: 'video-page', component: VideoPageComponent },
    { path: 'e-payment', component: EPaymentSystemComponent },
    
    { path: 'contact-o', component: ContactOComponent },
    
    


    { path: 'index-2', component: HomeDemoTwoComponent },
    { path: 'index-3', component: HomeDemoThreeComponent },
    { path: 'index-4', component: HomeDemoFourComponent },
    { path: 'index-5', component: HomeDemoFiveComponent },
    { path: 'index-6', component: HomeDemoSixComponent },
    { path: 'index-7', component: HomeDemoSevenComponent },
    { path: 'index-8', component: HomeDemoEightComponent },
    { path: 'index-9', component: HomeDemoNineComponent },
    { path: 'index-10', component: HomeDemoTenComponent },
    { path: 'index-1', component: HomeDemoOneComponent },
    { path: 'index-11', component: HomeDemoElevenComponent },
    { path: 'index-12', component: HomeDemoTwelveComponent },
    { path: 'about', component: AboutComponent },
    { path: 'about-2', component: AboutStyleTwoComponent },
    { path: 'services', component: ServicesComponent },
    { path: 'services-2', component: ServicesStyleTwoComponent },
    { path: 'services-3', component: ServicesStyleThreeComponent },
    { path: 'service-details', component: ServiceDetailsComponent },
    { path: 'projects', component: ProjectsComponent },
    { path: 'projects-2', component: ProjectsStyleTwoComponent },
    { path: 'projects-3', component: ProjectsStyleThreeComponent },
    { path: 'projects-4', component: ProjectsStyleFourComponent },
    { path: 'project-details', component: ProjectDetailsComponent },
    { path: 'blog', component: BlogComponent },
    { path: 'blog-2', component: BlogRightSidebarComponent },
    { path: 'blog-details', component: BlogDetailsComponent },
    { path: 'features', component: FeaturesComponent },
    { path: 'team', component: TeamComponent },
    { path: 'team-2', component: TeamStyleTwoComponent },
    { path: 'pricing', component: PricingComponent },
    { path: 'partner', component: PartnerComponent },
    { path: 'faq', component: FaqComponent },
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'contact', component: ContactComponent },
    // Here add new pages component

    { path: '**', component: HomeDemoTen2Component } // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }

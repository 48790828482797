<app-navbar-style-two-2></app-navbar-style-two-2>

<section class="blog-details-area pb-100"
    style="
background-image: url('assets/img/banner-image/body2.png');  
background-size: 100% ;  

background-repeat: repeat-y;
padding-top: 20px;
font-family: 'Congenial';">
    <div class="container"
        style="background: transparent;">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc"
                    style="background-color: #FFFFFF; padding: 20px; border: 1px solid #eeeeee;">
                    <div class="article-image">
                        <h2>
                            Principal's Message
                        </h2>
                        <img src="assets/img/banner-image/Dr-Muhammad-Liaquat-Ali.jpg"
                            alt="image">
                    </div>
                    <div class="article-content">

                        <p>
                            Hazrat Allama Hafiz Qari Syed Ahmad Shah Sirikoti (Ra) established Jamia Ahmadia Sunnia
                            Kamil Madrasa in 1954 at Sholshahar of Panchlaish Thana of Chittagong district. This madrasa
                            is being run on the basis of the Aqeedah and Hanafi Madhhab of Hazrat Imam Ahmad Reza Khan
                            Barelvi (Ra) and Ahle Sunnat Wal Jama'at. This madrasa has been playing an outstanding role
                            in the spread of Islamic education, the
                            propagation of Madhhab-e-Hanafi and Aqbayd-e-Ahle Sunnat. The education system of this
                            madrasa is unparalleled on the basis of the madrasa's own syllabus and the syllabus prepared
                            by the Bangladesh Madrasah Education Board and the Islamic Arabic University, including the
                            books of Fataawa-Fara'iz. It is one of the main madrasas and one of the top level madrasas
                            in the country under the management of 'Anjuman-e-Rahmania Ahmadia Sunnia Trust', a leading
                            spiritual organization of the country.
                        </p>
                        <p> It became one of the best madrasas in Asia under the
                            direct supervision of Gause Zaman Allama Hafiz Qari Shah Sufi Syed Muhammad Tayyab Shah
                            (Ra), the former patron of this madrasa.
                            The present patron of the madrasa, Gause Zaman
                            Rahnumae Shari'ah and Tareeqat Allama Syed Muhammad Taher Shah Maddazilluhul Ali and Hazrat
                            Shah Sufi Pir Syed Muhammad Sabir Shah Maddazilluhul Ali, under the direct supervision and
                            supervision of The Anjuman Trust. In the survey and observation of the government, this
                            madrasa has achieved the recognition of the best educational institution more than once and
                            the glory of model madrasa. From the Hifzul Qur'an Department, the hafizs who have completed
                            hifz are given the fazilat and certificates in dasta. The library, rich in ancient and
                            modern books, has further enhanced the tradition of this madrasa. In addition to teaching
                            Shari'ah, the practice of tariqat and spirituality is one of the characteristics of this
                            madrasa. This madrasa has been playing a huge role in spreading the aqeedah of Ahle Sunnat
                            wal Jama'at and developing the ideology of Hanafi Madhhab.

                        </p>
                        <div class="row">

                            <div class="col-lg-6 col-md-12">

                            </div>
                            <div class="col-lg-6 col-md-12">

                                <p>
                                    Dr. Muhammad Liaquat Ali
                                    <br>
                                    Principal
                                    <br>
                                    Jamea Ahmadia Sunnia Kamil Madrasah
                                </p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area"
                    id="secondary">

                    <section class="widget widget_karlson_posts_thumb"
                        style="background-color: #FFFFFF; padding: 20px; padding-top: 0; border: 1px solid #eeeeee;">

                        <div class="page-title-content"
                            style="margin-top: 0; text-align: left;">
                            <ul>
                                <li>
                                    <a routerLink="/">
                                        <i class="fa-solid fa-house"></i>
                                    </a>
                                </li>
                                <li>Administration</li>
                                <li>Principal's Message</li>
                            </ul>
                        </div>

                    </section>

                    <section class="widget widget_karlson_posts_thumb"
                        style="background-color: #FFFFFF; padding: 20px; border: 1px solid #eeeeee; margin-top: 15px;">
                        <h3 class="widget-title">Suggested Video</h3>

                        <iframe width="100%"
                            height="200"
                            src="https://www.youtube.com/embed/MAz9kRnvSYE"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen=""></iframe>

                    </section>

                    <section class="widget widget_categories"
                        style="background-color: #FFFFFF; padding: 20px; border: 1px solid #eeeeee; margin-top: 15px;">
                        <h3 class="widget-title">Related Topics</h3>

                        <ul>
                            <li>
                                <a routerLink="/governing-body">Governing Body</a>
                            </li>
                            <li>
                                <a routerLink="/chairman-message">Chairman Message</a>
                            </li>
                            <li>
                                <a routerLink="/principal-message">Principal Message</a>
                            </li>
                            <li>
                                <a routerLink="/teacher">Teacher</a>
                            </li>
                            <li>
                                <a routerLink="/office-staff">Office Staff</a>
                            </li>
                        </ul>

                    </section>

                </aside>
            </div>
        </div>
    </div>
</section>

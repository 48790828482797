<app-navbar-style-two-2></app-navbar-style-two-2>

<section class="blog-details-area pb-100"
    style="
background-image: url('assets/img/banner-image/body2.png');  
background-size: 100% ;  

background-repeat: repeat-y;
padding-top: 20px;
font-family: 'Congenial';">
    <div class="container"
        style="background: transparent;">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc"
                    style="background-color: #FFFFFF; padding: 20px; border: 1px solid #eeeeee;">
                    <div class="article-image">
                        <h2>
                            Academic Rules
                        </h2>
                        <img src="assets/img/banner-image/banner1-1.png"
                            alt="image">
                    </div>
                    <div class="article-content">

                        <h3>
                            Details To be Added
                        </h3>

                    </div>

                </div>
            </div>


        </div>
    </div>
</section>

<app-navbar-style-two-2></app-navbar-style-two-2>

<section class="blog-details-area pb-100"
    style="
background-image: url('assets/img/banner-image/body2.png');  
background-size: 100% ;  

background-repeat: repeat-y;
padding-top: 20px;
font-family: 'Congenial';">
    <div class="container"
        style="background: transparent;">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc"
                    style="background-color: #FFFFFF; padding: 20px; border: 1px solid #eeeeee;">
                    <div class="article-image">
                        <h2>
                            Syllabus & Booklist
                        </h2>
                    </div>
                    <div class="article-content">

                        <h3>
                            Data To be Added
                        </h3>

                    </div>

                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area"
                    id="secondary">

                    <section class="widget widget_karlson_posts_thumb"
                        style="background-color: #FFFFFF; padding: 20px; padding-top: 0; border: 1px solid #eeeeee;">

                        <div class="page-title-content"
                            style="margin-top: 0; text-align: left;">
                            <ul>
                                <li>
                                    <a routerLink="/">
                                        <i class="fa-solid fa-house"></i>
                                    </a>
                                </li>
                                <li>Academic</li>
                                <li>Syllabus & Booklist</li>
                            </ul>
                        </div>

                    </section>

                    <section class="widget widget_karlson_posts_thumb"
                        style="background-color: #FFFFFF; padding: 20px; border: 1px solid #eeeeee; margin-top: 15px;">
                        <h3 class="widget-title">Suggested Video</h3>

                        <iframe width="100%"
                            height="200"
                            src="https://www.youtube.com/embed/MAz9kRnvSYE"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen=""></iframe>

                    </section>

                    <section class="widget widget_categories"
                        style="background-color: #FFFFFF; padding: 20px; border: 1px solid #eeeeee; margin-top: 15px;">
                        <h3 class="widget-title">Related Topics</h3>

                        <ul>
                            <li>
                                <a routerLink="/academic-calendar">Academic Calendar</a>
                            </li>
                            <li>
                                <a routerLink="/class-routine">Class Routine</a>
                            </li>
                            <li>
                                <a routerLink="/syllabus-and-booklist">Syllabus & Booklist</a>
                            </li>
                            <li>
                                <a routerLink="/notice">Notice</a>
                            </li>
                            <li>
                                <a routerLink="/public-exam-result">Public Exam Result</a>
                            </li>
                            <li>
                                <a routerLink="/departments">Departments</a>
                            </li>
                            <li>
                                <a routerLink="/academic-rules">Academic Rules</a>
                            </li>
                        </ul>

                    </section>

                </aside>
            </div>
        </div>
    </div>
</section>
